<template>
  <el-popover
    placement="bottom"
    width="680"
    trigger="manual"
    popper-class="popperClass"
    @show="initLoadData"
    v-model="visible"
  >
    <div class="mainAllbox" slot="reference">
      <div class="border-icon" @click.stop="visible = !visible">
        <svg
          class="icon notice"
          aria-hidden="true"
          @mouseover="ChangeColorSelec($event)"
          @mouseout="ChangeColor($event)"
        >
          <use fill="#FFF" href="#icon-notice1"></use>
        </svg>
      </div>
      <!-- 铃铛上面的未读信息数 -->
      <div class="unreadNumber" v-if="this.unreadCount >= 1">
        <div
          class="messageNumberOne"
          v-if="this.unreadCount >= 1 && this.unreadCount <= 99"
        >
          {{ unreadCount }}
        </div>
        <div class="messageNumberTwo" v-else-if="this.unreadCount > 99">
          99+
        </div>
      </div>

      <!-- sse提示气泡 -->
      <div class="tips_alert" v-show="isShowTipAlert" @click.stop>
        <div class="box">
          <!-- 提示图标 -->
          <div class="top_wrapper">
            <span>您有1条新通知</span>
          </div>
          <div class="content">
            <!-- 提示文本 -->
            <div class="message">单击铃铛图标可查看您的未读通知。</div>
            <div class="btn_wrapper">
              <el-button
                type="primary"
                class="btn"
                size="small"
                plain
                @click.stop="isShowTipAlert = !isShowTipAlert"
                >知道了</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 消息面板 -->
    <div class="poposeeting" v-loading="messageLoading">
      <!-- 未读和已读 -->
      <!--只显示未读   vue_label_notificationcenter_unreadonly -->
      <div class="red">
        <div class="unread">
          <el-checkbox v-model="checked" @change="readchange()">{{
            $t("vue_label_notificationcenter_unreadonly")
          }}</el-checkbox>
          <!-- 测试 -->
        </div>
        <!-- 全部标记为已读    "label.h5.allmarkedasread"-->
        <div class="reding" @click="readAll">
          {{ $t("label.h5.allmarkedasread") }}
        </div>
      </div>
      <!-- tabs -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in countArr"
          :key="index"
          :label="`${item.label}(${item.total})`"
          :name="item.value"
        ></el-tab-pane>
      </el-tabs>

      <!-- 无数据时显示的内容 -->
      <div class="nonews" v-if="notificationData.length < 1">
        <div class="nonews-img">
          <svg class="icon nonewsImg" aria-hidden="true">
            <use href="#icon-notice"></use>
          </svg>
        </div>
        <!-- 您当前没有收到任何新通知   label.notification.tip2 -->
        <div class="tongzhi">{{ $t("label.notification.tip2") }}</div>
      </div>
      <!-- 有数据时显示的内容  -->

      <div
        v-else
        class="box"
        v-infinite-scroll="popoverData"
        :infinite-scroll-disabled="scrollDisabled"
      >
        <div
          class="infinite-list-item"
          v-for="(item, index) in notificationData"
          :key="index"
          :class="{ activeone: item.status == '0' }"
          @click="changeStyle(item)"
        >
          <div class="titleContent">
            <div class="dot">
              <svg class="icon" aria-hidden="true" v-if="item.status == '0'">
                <use href="#icon-yuan"></use>
              </svg>
            </div>
            <div class="leftTitle">
              <!-- 内容 -->
              <div class="theme">
                {{ item.contentpc }}
                <!-- {{item.contenttitlepc}} -->
              </div>
            </div>
            <div class="time">{{ item.msgTime }}</div>
          </div>
          <!-- 主题 -->
          <div class="mainContent">
            {{ item.contenttitlepc }}
            <!-- {{ item.contentpc }}  -->
          </div>
        </div>
      </div>
    </div>
  </el-popover>
</template>
<script>
import {
  queryPushMessage,
  queryPushMessageCount,
  changeReadStatus,
  todayTaskPushMessage,
  queryTaskUnread,
} from "./api";
// 邮箱是否关联
import * as Emailobject from "./api.js";
import axios from "axios";
axios.defaults.timeout = 50000;
axios.defaults.baseURL = window.Glod["ccex-apitsf"] + "/api";
import { mapState } from "vuex";

export default {
  data() {
    return {
      // 消息数据加载中
      messageLoading: false,
      //禁用滚动
      scrollDisabled: false,
      visible: false,
      page: 1,
      messageId: "",
      activeName: "All",
      notificationData: [],
      queryType: "All", ////默认为All,All为全部
      status: "0", //默认为0,0为未读
      checked: true,
      countArr: [],
      elseCount: "", //自定义的数量
      allCount: "", //总数量
      unreadCount: 0, //未读消息
      isrelationval: true,
      lingdangShow: false,
      // 是否显示及时消息气泡
      isShowTipAlert: false,
      wsseMessage: "",
    };
  },
  inject: ["reload"],
  computed: {
    ...mapState(["consoleData"]),
  },
  mounted() {
    /**
     * 请求未读消息数量的接口
     * 只作为首次加载时外面铃铛的未读消息数量展示
     */
    this.queryPushMessageCount();
    document.addEventListener("click", this.clickFn);
    // 监听wsse消息，并气泡弹出显示
    this.$bus.$on("wsseMessage", this.ebFn);
    this.$bus.$on("wsseBusEmit", this.updateMessage);
  },
  destroyed() {
    this.$bus.$off("wsseMessage", this.ebFn);
    this.$bus.$off("wsseBusEmit", this.updateMessage);
    document.removeEventListener("click", this.clickFn);
  },
  methods: {
    updateMessage() {
      this.page = 1;
      this.queryPushMessageCount();
    },
    initLoadData() {
      this.popoverData();
      this.getCounter();
    },
    ebFn(e) {
      this.wsseMessage = e;
      this.isShowTipAlert = true;
    },
    clickFn() {
      this.isShowTipAlert = false;
      this.visible = false;
    },
    // 通知中心图标颜色更改
    ChangeColorSelec() {
      this.lingdangShow = true;
    },
    ChangeColor() {
      this.lingdangShow = false;
    },

    // 今天到期或者逾期的任务消息推送
    async todayTaskPushMessage() {
      let params = {};
      await todayTaskPushMessage(params);
    },

    /**
     * 请求未读消息数量的接口
     * 只作为首次加载时外面铃铛的未读消息数量展示
     */
    queryPushMessageCount() {
      let params = {
        status: "0",
      };
      queryPushMessageCount(params).then((res) => {
        res.data.map((item) => {
          if (item.msgtype == "All") {
            this.unreadCount = item.total;
          }
        });
      });
    },

    //气泡出现
    async popoverData(config) {
      this.messageLoading = true;

      const { isReload } = config || {};
      if (isReload) {
        this.page = 1;
      }
      let params = {
        queryType: this.queryType,
        page: this.page,
        pageSize: 10,
        status: this.status,
      };
      this.scrollDisabled = true;
      // 获取消息
      let res = await queryPushMessage(params);
      if (isReload) {
        this.notificationData = [];
      }
      this.notificationData = [...this.notificationData, ...res.data];
      this.page++;
      this.scrollDisabled = false;

      // 今天到期或者逾期的任务消息推送
      this.todayTaskPushMessage();
      this.messageLoading = false;
    },
    /**
     * 查询数量
     * 计算得出未读的数量进行显示
     */
    async getCounter() {
      let { data } = await queryPushMessageCount({
        status: this.status,
      });
      // 全部 label.quickbooks.view.all   事件 label.event
      // 任务 label.task    邮件 label.ems.emails  审批项目 label.accraditation   小组 label.chat.micropost.a.group
      // 服务通知label.chat.serviceAppointment
      this.countArr = [
        // 所有tab标签
        {
          label: this.$i18n.t("label.quickbooks.view.all"),
          value: "All",
          total: "0",
        },
        {
          label: this.$i18n.t("label.event"),
          value: "eventAlert",
          total: "0",
        },
        {
          label: this.$i18n.t("label.task"),
          value: "taskAlert",
          total: "0",
        },
        {
          label: "CCChat",
          value: "showmsg",
          total: "0",
        },
        {
          label: this.$i18n.t("label.ems.emails"),
          value: "mailToCloudccApi",
          total: "0",
        },
        {
          label: this.$i18n.t("label.accraditation"),
          value: "approval",
          total: "0",
        },
        {
          label: this.$i18n.t("label.chat.micropost.a.group"),
          value: "ChatterGroup",
          total: "0",
        },
        {
          label: this.$i18n.t("label.chat.serviceAppointment"),
          value: "ServiceAppointment",
          total: "0",
        },
      ];
      if (this.status !== "") {
        let elseMsg = data.find((item) => {
          return item.msgtype === "else";
        });
        if (elseMsg) {
          data.forEach((item) => {
            if (item.msgtype === "All") {
              item.total = Number(item.total) - Number(elseMsg.total);
            }
          });
        }
      }

      // 根据后端返回有数据的标签进行匹配，修改对应的label
      data.map((item) => {
        this.countArr.map((item1) => {
          if (item.msgtype == item1.value) {
            item1.total = item.total;
          }
        });
      });
    },
    // tabs切换
    handleClick(tab) {
      this.queryType = tab.name;
      this.page = 1;
      this.popoverData({
        isReload: true, //重新加载，要清空之前的数据
      });
    },

    // 点击只显示未读,再点击显示所有已读和未读
    readchange() {
      if (this.checked == false) {
        // 显示所有消息
        this.status = "";
        this.popoverData({
          isReload: true, //重新加载，要清空之前的数据
        });
      } else {
        // 只显示未读消息
        this.status = "0";
        this.popoverData({
          isReload: true, //重新加载，要清空之前的数据
        });
      }
      // 切换未读已读后，要重新加载消息的统计数量
      this.getCounter();
    },

    // 全部标记为已读
    async readAll() {
      // 更改消息状态为已读接口;
      // 没有未读消息  vue_label_notificationcenter_nomessages
      if (this.notificationData.length == 0) {
        this.$message({
          showClose: true,
          message: this.$i18n.t("vue_label_notificationcenter_nomessages"),
        });
      } else {
        await changeReadStatus({
          queryType: "All",
        });
        this.popoverData();
        this.reload();
      }
    },

    // 点击信息跳转详情页--未读的通知会变成已读
    // item.id, item.relatedid, item.relatedobj, item.operatetype
    async changeStyle(item) {
      this.theMessageData = item;
      await changeReadStatus({
        queryType: "All",
        messageId: item.id,
      });
      this.popoverData();
      this.reload();

      // 邮件
      if (item.function == "mailToCloudccApi") {
        // eventbus 传值(邮件 id)
        this.$Bus.$emit("change-style", "acf20191021FeiAUjdys");
        if (item.relatedid.substr(0, 3) === "025") {
          // 当前用户是否关联邮箱判断
          Emailobject.getEmailPageInfo({})
            .then((res) => {
              this.isrelationval = res.data.relatedActiveMailSetting;
              // 邮件数据是否删除提前判断
              let option = {
                id: item.relatedid,
              };
              axios
                .post("emailObject/detail", option, {
                  headers: {
                    accesstoken: this.$CCDK.CCToken.getToken(),
                  },
                })
                .then(() => {
                  // let res = response.data;
                  if (this.isrelationval === true) {
                    // 应用程序为控制台样式需要生成一级菜单
                    if (this.$store.state.navigationStyle) {
                      let menuObj = {
                        name: item.contenttitlepc || item.contentpc, // tab名称
                        id: item.relatedid, //id，唯一标识tab的
                        routerPath: `/MailDetail`, //路由的名字
                        query: {
                          id: item.relatedid,
                          NotificationCenter: false,
                        }, // 页面需要的参数
                      };
                      window.$CCDK.CCMenu.addMenu1(menuObj);
                    }
                    this.$router.push({
                      path: `/MailDetail`,
                      query: {
                        id: item.relatedid,
                        NotificationCenter: false,
                      },
                    });
                  } else {
                    //您还未启用邮箱
                    this.$message({
                      showClose: true,
                      message: this.$i18n.t(
                        "vue_label_email_You_have_not_activated_the_mailbox"
                      ),
                      type: "warning",
                    });
                  }
                })
                .catch(() => {
                  // 数据不存在或已删除  message.nodataordeleted
                  this.$message(this.$i18n.t("message.nodataordeleted"));
                });
            })
            .catch(() => {});
        } else {
          // 应用程序为控制台样式需要生成一级菜单
          if (this.$store.state.navigationStyle) {
            let menuObj = {
              name: item.contenttitlepc || item.contentpc, // tab名称
              id: item.relatedid, //id，唯一标识tab的
              routerPath: `/noteSms/noteSmsList`, //路由的名字
              query: {
                id: item.relatedid,
              }, // 页面需要的参数
            };
            window.$CCDK.CCMenu.addMenu1(menuObj);
          }
          this.$router.push({
            path: `/noteSms/noteSmsList`,
            query: {
              id: item.relatedid,
            },
          });
        }
        //事件
      } else if (item.function == "eventAlert") {
        // 请求查看任务事件未读通知接口  分配的事件被查看提醒通知
        let params = {
          taskId: item.relatedid,
        };
        await queryTaskUnread(params);
        // eventbus 传值(日历 id)
        this.$Bus.$emit("change-style", "acf20200615event");

        //  方法2
        let option = {
          id: item.relatedid,
        };
        axios
          .post("objectdetail/getPermissionById", option, {
            headers: {
              accesstoken: this.$CCDK.CCToken.getToken(),
            },
          })
          .then((response) => {
            let res = response.data;
            // 数据不存在或已删除  message.nodataordeleted
            if (res.result == false) {
              this.$message(this.$i18n.t("message.nodataordeleted"));
            } else {
              if (this.$store.state.navigationStyle) {
                // 应用程序为控制台样式  打开一级菜单
                this.$CCDK.CCPage.openDetailPage(
                  {
                    oprateType: "DETAIL",
                    objectName: item.contenttitlepc,
                    objId: this.consoleData.objId,
                    objectApi: this.consoleData.objectApi,
                    prefix: this.consoleData.prefix,
                  },
                  item.relatedid,
                  { openPlace: "menu1", openMode: "_blank" }
                );
              } else {
                this.$router.push({
                  path: `/commonObjects/detail/${item.relatedid}/DETAIL`,
                });
              }
            }
          });
        //任务
      } else if (item.function == "taskAlert") {
        // 请求查看任务事件未读通知接口  分配的任务被查看提醒通知
        let params = {
          taskId: item.relatedid,
        };
        await queryTaskUnread(params);
        // eventbus 传值(任务 id)
        this.$Bus.$emit("change-style", "acf20200615task");

        //  方法2
        let option = {
          id: item.relatedid,
        };
        axios
          .post("objectdetail/getPermissionById", option, {
            headers: {
              accesstoken: this.$CCDK.CCToken.getToken(),
            },
          })
          .then((response) => {
            let res = response.data;
            // 数据不存在或已删除  message.nodataordeleted
            if (res.result == false) {
              this.$message(this.$i18n.t("message.nodataordeleted"));
            } else {
              if (this.$store.state.navigationStyle) {
                // 应用程序为控制台样式  打开一级菜单
                this.$CCDK.CCPage.openDetailPage(
                  {
                    oprateType: "DETAIL",
                    objectName: item.contenttitlepc,
                    objId: this.consoleData.objId,
                    objectApi: this.consoleData.objectApi,
                    prefix: this.consoleData.prefix,
                  },
                  item.relatedid,
                  { openPlace: "menu1", openMode: "_blank" }
                );
              } else {
                this.$router.push({
                  path: `/commonObjects/detail/${item.relatedid}/DETAIL`,
                });
              }
            }
          });
        // 小组
      } else if (item.function == "ChatterGroup") {
        // eventbus 传值(同事圈 id)
        this.$Bus.$emit("change-style", "acf201200141050RgtYi");
        // 应用程序为控制台样式需要生成一级菜单
        if (this.$store.state.navigationStyle) {
          let menuObj = {
            name: item.contenttitlepc || item.contentpc, // tab名称
            id: item.relatedid, //id，唯一标识tab的
            routerPath: `/colleagueCircle/dynamic`, //路由的名字
            query: {
              id: item.relatedid,
              category: item.function,
            }, // 页面需要的参数
          };
          window.$CCDK.CCMenu.addMenu1(menuObj);
        }
        this.$router.push({
          path: `/colleagueCircle/dynamic`,
          query: {
            id: item.relatedid,
            category: item.function,
          },
        }); // 云帖 CCChat
      } else if (item.function == "showmsg") {
        // eventbus 传值(同事圈 id)
        this.$Bus.$emit("change-style", "acf201200141050RgtYi");
        if (item.operatetype == "follow") {
          if (this.$store.state.navigationStyle) {
            // 应用程序为控制台样式  打开一级菜单
            this.$CCDK.CCPage.openDetailPage(
              {
                oprateType: "DETAIL",
                objectName: item.contenttitlepc,
                objId: this.consoleData.objId,
                objectApi: this.consoleData.objectApi,
                prefix: this.consoleData.prefix,
              },
              item.relatedid,
              { openPlace: "menu1", openMode: "_blank" }
            );
          } else {
            this.$router.push({
              path: `/commonObjects/detail/${item.relatedid}/DETAIL`,
            });
          }
        } else if (item.operatetype == "null") {
          // 自定义页面创建   label.version.nonsupport.custompage
          this.$message(this.$i18n.t("label.version.nonsupport.custompage"));
        } else {
          // 应用程序为控制台样式需要生成一级菜单
          if (this.$store.state.navigationStyle) {
            let menuObj = {
              name: item.contenttitlepc || item.contentpc, // tab名称
              id: item.relatedid, //id，唯一标识tab的
              routerPath: `/colleagueCircle/dynamic`, //路由的名字
              query: {
                id: item.relatedid,
                category: item.function,
              }, // 页面需要的参数
            };
            window.$CCDK.CCMenu.addMenu1(menuObj);
          }
          this.$router.push({
            path: `/colleagueCircle/dynamic`,
            query: {
              id: item.relatedid,
              category: item.function,
            },
          });
        }
        // 审批
      } else if (item.function == "approval") {
        // eventbus 传值(主页 id)
        this.$Bus.$emit("change-style", "acf000001");
        // 获取对象记录权限 接口  判断该条消息数据是否被删除或权限更改

        //   方法2
        let option = {
          id: item.relatedid,
        };
        axios
          .post("objectdetail/getPermissionById", option, {
            headers: {
              accesstoken: this.$CCDK.CCToken.getToken(),
            },
          })
          .then((response) => {
            let res = response.data;
            // 数据不存在或已删除  message.nodataordeleted
            if (res.result == false) {
              this.$message(this.$i18n.t("message.nodataordeleted"));
            } else {
              // 审批类型是正在审批的
              if (
                item.work_item_id &&
                item.work_item_id != null &&
                item.work_item_id != ""
              ) {
                let option = {
                  objid: item.relatedid,
                  workItemid: item.work_item_id,
                };
                // 应用程序为控制台样式需要生成一级菜单
                if (this.$store.state.navigationStyle) {
                  let menuObj = {
                    name: item.contenttitlepc, // tab名称
                    id: item.relatedid, //id，唯一标识tab的
                    routerPath: `/approvalCenter/approvalRequest`, //路由的名字
                    query: { value: JSON.stringify(option) }, // 页面需要的参数
                  };
                  window.$CCDK.CCMenu.addMenu1(menuObj);
                }
                this.$router.push({
                  path: `/approvalCenter/approvalRequest`,
                  query: { value: JSON.stringify(option) },
                });
              } else {
                // 审批类型是已经审批完成或拒绝审批完成的
                if (this.$store.state.navigationStyle) {
                  // 应用程序为控制台样式  打开一级菜单
                  this.$CCDK.CCPage.openDetailPage(
                    {
                      oprateType: "DETAIL",
                      objectName: item.contenttitlepc,
                      objId: this.consoleData.objId,
                      objectApi: this.consoleData.objectApi,
                      prefix: this.consoleData.prefix,
                    },
                    item.relatedid,
                    { openPlace: "menu1", openMode: "_blank" }
                  );
                } else {
                  this.$router.push({
                    path: `/commonObjects/detail/${item.relatedid}/DETAIL`,
                  });
                }
              }
            }
          });
      } else {
        if (this.$store.state.navigationStyle) {
          // 应用程序为控制台样式  打开一级菜单
          this.$CCDK.CCPage.openDetailPage(
            {
              oprateType: "DETAIL",
              objectName: item.contenttitlepc,
              objId: this.consoleData.objId,
              objectApi: this.consoleData.objectApi,
              prefix: this.consoleData.prefix,
            },
            item.relatedid,
            { openPlace: "menu1", openMode: "_blank" }
          );
        } else {
          this.$router.push({
            path: `/commonObjects/detail/${item.relatedid}/DETAIL`,
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
.el-popover.popperClass {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
// // el-tabs样式
::v-deep .el-tabs__item {
  width: 80px; //无自定义时 96px
  padding: 0;
  text-align: center;
}
::v-deep .el-tabs__active-bar {
  width: 57px !important; //无自定义时 74px
  left: 11px;
}
.mainAllbox {
  position: relative;
  margin-left: 10px;
  // border: solid 1px;
  /* 通知中心 */
  .notice {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    padding: 1px;
    &:hover {
      background: #585d71;
      use {
        fill: #abc4fe;
      }
    }
  }
  .big-notice {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
  //  铃铛上面的未读信息数
  .unreadNumber {
    width: 20px;
    height: 15px;
    position: absolute;
    background-color: #ff6600;
    border-radius: 10px;
    color: white;
    text-align: center;
    line-height: 15px;
    right: -8px;
    top: -3px;
    cursor: pointer;
    .messageNumberOne {
      font-size: 20px;
      // -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    .messageNumberTwo {
      font-size: 18px;
      margin-right: 10px;
      // -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
  }
  .border-icon {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // sse提示气泡
  .tips_alert {
    position: absolute;
    top: 45px;
    right: -68px;
    box-shadow: 1px 2px 4px #ccc;
    background-color: #fff;
    width: 260px;
    z-index: 999999999;
    border-radius: 5px;
    .box {
      padding: 0;
      &:after {
        content: "";
        position: absolute;
        top: -9px;
        right: 70px;
        width: 20px;
        height: 10px;
        background: conic-gradient(
          from 90deg at 50% 0,
          transparent 0,
          transparent 45deg,
          #2d6cfc 45.1deg,
          #2d6cfc 135deg,
          transparent 1deg
        );
      }
      // 顶部title
      .top_wrapper {
        height: 40px;
        background-color: #2d6cfc;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 0 16px;
      }
      .content {
        padding: 16px;
        .btn_wrapper {
          display: flex;
          justify-content: flex-end;
          .btn {
            display: flex;
            align-items: center;
            margin-top: 10px;
            width: 60px;
            height: 28px;
          }
        }
      }
    }
  }
}
.poposeeting {
  font-size: 14px;
  .red {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1px;
    padding: 18px 16px 0px 16px;
    .reding {
      &:hover {
        cursor: pointer;
        color: #006dcc;
      }
    }
  }

  // 无数据时显示的内容
  .nonews {
    text-align: center;
    height: 250px;
    padding: 30px;
    .nonews-img {
      margin-top: 26px;
      .nonewsImg {
        display: inline-block;
        // border: solid 1px;
        width: 170px;
        height: 102px;
      }
    }
    .tongzhi {
      margin-top: 18px;
    }
  }

  // 有数据时显示的内容
  .box {
    height: 250px;
    overflow: auto;
    .activeone {
      font-weight: 600;
    }
    .infinite-list-item {
      // border: solid 1px;
      padding: 2px 0 8px 0;
      cursor: pointer;
      .titleContent {
        display: flex;
        // justify-content: space-between;
        .dot {
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          .icon {
            width: 6px;
            height: 6px;
          }
        }
        .leftTitle {
          width: 60%;
          .theme {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .time {
          width: 35%;
          text-align: right;
        }
      }
      .mainContent {
        width: 58%;
        height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 3%;
        padding: 1px 0;
      }
    }
  }
}
</style>
